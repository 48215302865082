import React from "react";

import { AccessAlarm ,AlarmOn ,Delete,Edit} from '@material-ui/icons';
import { Button, List, ListItem, ListItemText, ListItemAvatar, Avatar,ListItemSecondaryAction,IconButton } from '@material-ui/core';


export default class Items extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            todoList:this.props.list,
            viewCompleted:this.props.complete,
        }
    }

    componentWillReceiveProps(nextProps){  
        this.setState({todoList: nextProps.list})  
    }


    displayCompleted = status => {
        if(status){
            return this.setState({ viewCompleted: true });
        }
        return this.setState({ viewCompleted: false });
    }
    displayAll(){
        this.setState({ viewCompleted: 'all' });
    }

    render(){
        const { todoList, viewCompleted } = this.state
        const { editItem, handleDelete } = this.props
        const newItems = this.state.todoList.filter(
            
            item =>viewCompleted === 'all' ? item : item.completed === viewCompleted
        )
        return( 
            <>
            <List>
            {todoList.length > 0 ? newItems.map(item => (

            <ListItem key={item.id}>
                <ListItemAvatar>
                    <Avatar>
                        {item.completed ? <AlarmOn /> : <AccessAlarm color="secondary"/>}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={item.title} secondary={item.description} />
                <ListItemSecondaryAction>
                <IconButton onClick={() => editItem(item)}>
                    <Edit />
                </IconButton>
                <IconButton edge="end" onClick={() => handleDelete(item)}>
                    <Delete />
                </IconButton>
                </ListItemSecondaryAction>
            </ListItem>

            )) : "loading"}
            </List>
            <Button
            variant="contained"
            color="primary"
            onClick={() => this.displayCompleted(true)}
            className={`mt-3 mb-2 mr-1 ml-2`}
            >
            complete</Button>
            <Button
            variant="contained"
            color="primary"
            onClick={() => this.displayCompleted(false)}
            className={`mt-3 mb-2`}
            >
            Incomplete</Button>
            <Button
            variant="contained"
            color="secondary"
            onClick={() => this.displayAll()}
            className={`mt-3 ml-1 mb-2`}
            >
            All</Button>
            </>
        )
    }
} 