import React from 'react';
import './App.css';
import axios from "axios";
import { Button, Grid, Paper,Container } from '@material-ui/core';


import Items from "./components/Items";
import Modal from "./components/Modal";



class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      errorMsg:'',
      todoList: [],
      viewCompleted:false,
      activeItem:{
        title: "",
        description: "",
        completed: false
      }
    }
    this.refreshList = this.refreshList.bind(this)
  }
  componentDidMount(){
    this.refreshList();
  }
  refreshList = () => {
    axios
    .get("https://djangoapi.ct8.pl/api/todos/")
    .then(res => this.setState({ todoList: res.data }))
    .catch(err => console.log(err))
  }
  toggle = () => {
    this.setState({ modal: !this.state.modal,errorMsg:'' });

  }

  handleSubmit = item => {
    
    if(item.id){
      axios
      .put(`https://djangoapi.ct8.pl/api/todos/${item.id}/`, item)
      .then(res => {
        this.toggle();
        this.refreshList()
      });
    }else{
      axios
        .post("https://djangoapi.ct8.pl/api/todos/", item)
        .then(res => {
          this.toggle();
          this.refreshList()
        })
        .catch(err => {
          this.setState({ errorMsg: err.response.data })
          console.log(err.response.data)
        })   
    }
  }
  handleDelete = item => {
    axios
      .delete(`https://djangoapi.ct8.pl/api/todos/${item.id}/`)
      .then(res => this.refreshList())
  }

  createItem = () => {
    const item = {title: "", description:"", completed: false };
    this.setState({ activeItem: item, modal: !this.state.modal });
  }
  editItem = item => {
    this.setState({ activeItem: item, modal: !this.state.modal });
  }
  


  render(){
    const { todoList, viewCompleted,errorMsg } = this.state
    
    return (
      <Container maxWidth="sm" className="mt-5">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper>
          {todoList.length > 0 ?
          <>  
          <Button 
          variant="contained" 
          className="mt-2 mb-2 ml-2"
          onClick={this.createItem}>Add Task</Button>
          <Items 
          list={todoList} 
          complete={viewCompleted}
          editItem={this.editItem}
          handleDelete={this.handleDelete} 
          /></> : "loading"}
          </Paper>
        </Grid>
      </Grid>
        {this.state.modal ? (
        <Modal
        activeItem={this.state.activeItem}
        toggle={this.toggle}
        onSave={this.handleSubmit}
        errorMsg={errorMsg}
        />
        ) : null}

      </Container>
    );
  }
}

export default App;
